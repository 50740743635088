<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Функционал: Рассылки</strong>
    </div>

    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Редактировать рассылку</h5>
        </div>
      </div>

      <div class="card-body">
        <a-form-model ref="form" :model="form">
          <a-form-model-item label="Тип" required>
            <a-select v-model="form.status_notice_id" placeholder="выберите статус">
              <a-select-option v-for="type in types" :key="type.id" :value="type.id">
                {{ type.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-item label="Отправить всем">
            <div class="d-flex align-items-center">
              <a-switch v-model="form.all" />
            </div>
          </a-form-item>

          <a-form-model-item label="Получатели">
            <a-select
              v-model="selectedUsers"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              :disabled="form.all"
              allowClear
              show-search
              multiple="true"
              mode="multiple"
              style="width: 100%"
              @popupScroll="infiniteScroll($event)"
              @select="onSelectUser($event)"
              @search="handleSearch"
              placeholder="выберите получателей"
            >
              <a-select-option v-for="user in users" :key="user.id" :value="user.id">
                {{ user.first_name }} {{ user.last_name }} {{ user.email }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item ref="active" label="Тема(ru)" required>
            <a-input v-model.number="form.ru.title"/>
          </a-form-model-item>
          <a-form-model-item ref="active" label="Тема(ua)" required>
            <a-input v-model.number="form.ua.title"/>
          </a-form-model-item>
          <a-form-model-item ref="active" label="Содержимое(ru)" required>
            <a-input :maxLength="255"  v-model="form.ru.name"/>
           <label>максимальная длина 255 символов</label>
          </a-form-model-item>
          <a-form-model-item ref="active" label="Содержимое(ua)" required>
            <a-input :maxLength="255" v-model="form.ua.name"/>
             <label>максимальная длина 255 символов</label>
          </a-form-model-item>
          <a-form-model-item ref="active" label="Лого">
            <a-input placeholder="https://api.autosill.ua/storage/settings/logo.png" v-model="form.ru.mail.logo"/>
          </a-form-model-item>
           <div>Добавить ссылки</div>
          <a-form-model-item ref="active" label="Заголовок 1" >
            <a-input v-model.number="form.ru.mail.links[0].title"/>
          </a-form-model-item>
          <a-form-model-item ref="active" label="Ссылка 1" >
            <a-input placeholder="https://autosill.ua/" v-model.number="form.ru.mail.links[0].url"/>
          </a-form-model-item>
          <a-form-model-item ref="active" label="Заголовок 2" >
            <a-input v-model.number="form.ru.mail.links[1].title"/>
          </a-form-model-item>
          <a-form-model-item ref="active" label="Ссылка 2" >
            <a-input placeholder="https://autosill.ua/" v-model.number="form.ru.mail.links[1].url"/>
          </a-form-model-item>
          <a-form-model-item ref="active" label="Заголовок 3" >
            <a-input v-model.number="form.ru.mail.links[2].title"/>
          </a-form-model-item>
          <a-form-model-item ref="active" label="Ссылка 3" >
            <a-input placeholder="https://autosill.ua/" v-model.number="form.ru.mail.links[2].url"/>
          </a-form-model-item>
          <a-form-model-item ref="active" label="Заголовок 4" >
            <a-input  v-model.number="form.ru.mail.links[3].title"/>
          </a-form-model-item>
          <a-form-model-item ref="active" label="Ссылка 4" >
            <a-input placeholder="https://autosill.ua/" v-model.number="form.ru.mail.links[3].url"/>
          </a-form-model-item>
        </a-form-model>
        <div class="d-flex  justify-content-between">
        <a-button class="d-block" @click="goToPreview" >
          Предварительный просмотр
        </a-button>
        <a-button class="d-block ml-auto" type="primary" @click="submit" :disabled="isDisabled">
          Готово
        </a-button>
        </div>
      </div>
    </div>
     <review-modal ref="reviewModal"/>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import ReviewModal from '@/components/functional/mailings/ReviewModal'

export default {
  name: 'edit',

  components: {
    ReviewModal,
  },

  data() {
    return {
      form: {
        ru: {
          name: '',
          title: '',
          mail: {
            logo: '',
            links: [
              { title: '', url: '' },
              { title: '', url: '' },
              { title: '', url: '' },
              { title: '', url: '' },
            ],
          },
        },
        ua: {
          name: '',
          title: '',
        },
        all: false,
        status_notice_id: null,
        recipient_id: [],
      },
      types: [],
      users: [],
      search: '',
      pagination: {},
      selectedUsers: [],
      notice: {},
      recipientArr: [],
      usersSelect: [],
      limitingRequests: false,
    }
  },
  created() {
    Promise.all([this.getNotice(), this.getUsers(), this.getTypes()])
  },
  watch: {
    'form.all'(val) {
      if (val) {
        this.form.recipient_id = []
      }
    },
    search() {
      this.users = []
      this.pagination.current_page = 1
      this.getUsers()
    },
    users: {
      deep: true,
      handler() {
        this.users.forEach((user) => {
          this.selectedUsers = this.selectedUsers.filter((selectedUser) => user.id !== selectedUser.id)
        })
      },
    },
  },
  computed: {
    isDisabled() {
      let isDisabled = false
      const form = this.form
      const { ru, ua } = form
      if (!`${form.status}` || !ru.name || !ru.title || !ua.name || !ua.title) isDisabled = true
      if (!form.all && !this.selectedUsers.length) isDisabled = true
      if (this.limitingRequests === true) isDisabled = true
      return isDisabled
    },
  },
  methods: {
    async getNotice() {
      const { data: { data: { notice } } } = await this.$services.get(`admin/notice/edit/${this.$route.params.id}`)
      this.form = notice
      if (this.form.all === 0) {
        this.form.all = false
      } else { this.form.all = true }
      this.form.status_notice_id = notice.status_id
      this.selectedUsers = notice.recipient?.map((u) => u.id)
      this.recipientArr = notice.recipient
      if (this.form.ru.mail.logo === null) {
        this.form.ru.mail.logo = ''
      }
      for (let i = 0; i < this.form.ru.mail.links.length; i += 1) {
        if (this.form.ru.mail.links[i].title === null) {
          this.form.ru.mail.links[i].title = ''
        }
        if (this.form.ru.mail.links[i].url === null) {
          this.form.ru.mail.links[i].url = ''
        }
      }
    },
    getUsers: debounce(async function() {
      const params = {
        search: this.search,
        page: this.pagination.current_page ? this.pagination.current_page : 1,
      }
      try {
        const users = (await this.$services.get('admin/users', { params })).data.data.values
        this.users = this.users.concat(users.data)
        this.pagination = users.pagination
        for (let i = 0; i < this.users.length; i += 1) {
          for (let i = 0; i < this.selectedUsers.length; i += 1) {
            const userId = this.selectedUsers[i]
            const userIdArr = this.users.map((u) => u.id)
            if (userIdArr.includes(userId)) {
              this.users = this.users.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
            } else {
              this.users = [...this.users, ...this.recipientArr]
            }
          }
        }

        for (let i = 0; i < this.users.length; i += 1) {
          for (let i = 0; i < this.usersSelect.length; i += 1) {
            const userId = this.usersSelect[i].id
            const userIdArr = this.users.map((u) => u.id)
            if (userIdArr.includes(userId)) {
              this.users = this.users.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
            } else {
              this.users = [...this.users, ...this.usersSelect]
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    }, 200),

    async getTypes() {
      try {
        this.types = (await this.$services.get('admin/noticeStatus')).data.data.values
      } catch (e) {
        console.log(e)
      }
    },

    goToPreview() {
      this.$refs.reviewModal.open(this.form)
    },

    handleSearch(value) {
      this.search = value
    },
    onSelectUser(userId) {
      if (this.search) {
        this.usersSelect.push(this.users.find((user) => user.id === userId))
        this.getUsers()
      }
      this.search = ''
    },
    infiniteScroll(e) {
      const target = e.target
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        if (this.pagination.current_page < this.pagination.total_pages) {
          ++this.pagination.current_page
          this.getUsers()
        }
      }
    },
    async submit() {
      this.form.recipient_id = [...this.selectedUsers]
      try {
        this.limitingRequests = true
        await this.$services.post('admin/notice', this.form)
        this.form = {
          ru: {
            name: '',
            title: '',
          },
          ua: {
            name: '',
            title: '',
          },
          all: false,
          status_notice_id: null,
          recipient_id: [],
          mail: {
            logo: '',
            links: [
              { title: '', url: '' },
              { title: '', url: '' },
              { title: '', url: '' },
              { title: '', url: '' },
            ],
          },
        }
        this.$router.push({ path: '/functional/mailings' })
        this.limitingRequests = false
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
<style scoped>
</style>
